import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import {
  // FETCH_PRODUCT,
  // NEW_PRODUCT,
  // UPDATE_PRODUCT,
  // ERROR_NEW_PRODUCT,
  // ERROR_UPDATE_PRODUCT,
  ADD_COMMAND,
  ERROR_ADD_COMMAND,
  CHOOSE_PRODUCT,
  FETCH_COMMANDS,
  ERROR_FETCH_COMMANDS,
} from './types';
import 'firebase/firestore';

export const addCommand = (command, user) => {
  const db = firebase.firestore();
  /* if (window.location.hostname === 'localhost') {
    try {
      db.settings({
        host: 'localhost:6001',
        ssl: false,
      });
    } catch (error) {
      // console.error(error);
    }
  } */

  return (dispatch) => {
    const mewCommand = {
      id: uuidv4(),
      user,
      userId: user && user.uid ? user.uid : 'n/a',
      status: 'pending',
      type: 'unique',
      address: user && user.address ? user.address : '',
      price: command && command.price ? command.price : '0CHF',
      quantity: command && command.quantity ? command.quantity : 0,
      customer: user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : 'n/a',
      tel: user && user.phoneNumber ? user.phoneNumber : 'n/a',
      date: Date.now(),
    };
    const commandRef = db.collection('commands').doc(mewCommand.id);

    commandRef.set(mewCommand)
      .then(() => {
        dispatch({
          type: ADD_COMMAND,
          payload: {
            new_command: mewCommand,
          },
        });
      })
      .catch((error) => {
        // console.log('Error Add command: ', error);
        dispatch({
          type: ERROR_ADD_COMMAND,
          payload: { error },
        });
      });
  };
};

export const chooseProduct = (product) => (dispatch) => {
  dispatch({
    type: CHOOSE_PRODUCT,
    payload: { product },
  });
};

export const fetchCommands = () => {
  const db = firebase.firestore();

  return (dispatch) => {
    const userRef = db.collection('commands');

    userRef.where('status', '==', 'pending').orderBy('date', 'desc').limit(200).get()
      .then((commands) => {
        const dataCommands = [];
        commands.forEach((command) => {
          dataCommands.push(command.data());
        });
        dispatch({
          type: FETCH_COMMANDS,
          payload: { commands: dataCommands },
        });
      })
      .catch((error) => {
        // console.log('Error getting document:', error);
        dispatch({
          type: ERROR_FETCH_COMMANDS,
          payload: { error },
        });
      });
  };
};

export const deleteCommands = (IDS, callback) => {
  const db = firebase.firestore();

  return (dispatch) => {
    const userRef = db.collection('commands');

    userRef.where('id', 'in', IDS).limit(100).get()
      .then((commands) => {
        const batch = firebase.firestore().batch();
        commands.docs.forEach((doc) => {
          const docRef = firebase.firestore().collection('commands').doc(doc.id);
          batch.update(docRef, {
            status: 'delivered',
          });
        });
        batch.commit().then(() => {
          // console.log('updated all documents inside commands');
          callback();
        });
      })
      .catch((error) => {
        // console.log('Error getting document:', error);
        dispatch({
          type: ERROR_FETCH_COMMANDS,
          payload: { error },
        });
      });
  };
};

/* export const fetchProduct = (productID) => {
  const db = firebase.firestore();
  if (window.location.hostname === 'localhost') {
    try {
      db.settings({
        host: 'localhost:6001',
        ssl: false,
      });
    } catch (error) {
      // console.error(error);
    }
  }
};

export const updateProduct = (product, payload, callback) => {
  const db = firebase.firestore();
  if (window.location.hostname === 'localhost') {
    try {
      db.settings({
        host: 'localhost:6001',
        ssl: false,
      });
    } catch (error) {
      // console.error(error);
    }
  }
  const productUid = product.uid;
  return (dispatch) => {
    const productRef = db.collection('products').doc(productUid);

    productRef.update(payload)
      .then(() => {
        if (callback) { callback(); }
        dispatch({
          type: UPDATE_USER,
          payload: { product },
        });
      })
      .catch((error) => {
        console.log('Error updating Product: ', error);
        dispatch({
          type: ERROR_UPDATE_USER,
          payload: { error },
        });
      });
  };
};

export const updateLocalUser = (user) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: { user },
  });
};

export const getUserLocation = () => (dispatch) => {
  fetch('https://ipinfo.io/json')
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (contentType && (contentType.indexOf('application/json') !== -1)) {
        return response.json().then((location) => {
          dispatch({
            type: GET_USER_LOCATION,
            payload: { location },
          });
        });
      }
      console.log('Error parsing JSON!', '');
      return response.text().then(() => {
        dispatch({
          type: ERROR_FETCH_USER,
          payload: { error: true },
        });
      });
    })
    .catch((error) => {
      console.log('Error user location: ', error);
    });
};

export const updateUserGeolocation = (mapGeolocation) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_GEOLOCATION,
    payload: { mapGeolocation },
  });
};

 */
