import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import validator from 'validator';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { NavLink, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
// import { fetchUser } from '../actions/userActions';
import 'firebase/auth';
import { useState } from 'react';
// import { useDispatch } from 'react-redux';
import { FormHelperText } from '@material-ui/core';
import Logo from '../assets/images/logo_leguman.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paragraph: {
    width: '100%',
    textAlign: 'center',
    display: 'block',
  },
  formLabel: {
    '& span': {
      fontSize: '1em',
    },
  },
  socialIcon: {
    marginRight: '0.5em',
  },
  link: {
    color: '#263431',
  },
  globalError: {
    color: 'red',
  },
  media: {
    height: '200px',
    width: '100%',
    paddingTop: '56.25%', // 16:9
  },
  logo: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '1em',
  },
  textField: {
    '& > div': {
      backgroundColor: '#FFFFFF !important',
    },
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkbox, setCheckbox] = useState(true);
  const [globalError, setGlobalError] = useState(false);
  // const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    checkbox: false,
  });
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: validator.isEmail(event.target.value) ? false : { message: 'Veuillez fournir une adresse électronique valide' },
        });
        break;
      case 'password':
        setPassword(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(password) ? false : { message: 'Ce champ est requis' },
        });
        break;
      default:
        setCheckbox(event.target.checked);
    }
  };

  const handleValidate = () => {
    setErrors({
      email: validator.isEmail(email) ? false : { message: 'Veuillez fournir une adresse électronique valide' },
      password: !validator.isEmpty(password.trim()) ? false : { message: 'Ce champ est requis' },
      checkbox: false,
    });

    return (validator.isEmail(email) && !validator.isEmpty(password));
  };

  const handleSignIn = (payload) => {
    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithEmailAndPassword(payload.email, payload.password).then(() => {
      history.push('/products');
    }).catch(() => {
      setGlobalError(true);
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const validate = await handleValidate();

    if (validate) {
      handleSignIn({
        email, password,
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}><img height={30} src={Logo} alt="leguman" /></div>
        <Typography component="h1" variant="h5">
          S&apos;identifier
        </Typography>
        <form onSubmit={onSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                value={email}
                onChange={handleChange}
                variant="filled"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                inputProps={{
                  name: 'email',
                }}
                autoComplete="email"
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                value={password}
                onChange={handleChange}
                variant="filled"
                required
                fullWidth
                name="password"
                inputProps={{
                  name: 'password',
                }}
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : ''}
              />
            </Grid>
            <FormControlLabel
              control={(
                <Checkbox
                  value="remember"
                  color="primary"
                  name="remember"
                  inputProps={{
                    name: 'remember',
                  }}
                  checked={checkbox}
                  onChange={handleChange}
                />
)}
              label="Se souvenir de moi"
            />
          </Grid>
          {
            globalError
              ? (
                <FormHelperText className={classes.globalError}>
                  Adresse e-mail ou mot de passe invalide
                </FormHelperText>
              )
              : null
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Se connecter
          </Button>
          <Grid container>
            <Grid item xs={12}>
              <span className={classes.link}>
                Mot de passe oublié ?
              </span>
            </Grid>
            <Grid item xs={12}>
              <NavLink className={classes.link} to="/register">
                <span>
                  {'Vous n\'avez pas de compte ? Inscrivez-vous'}
                </span>
              </NavLink>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8} />
    </Container>
  );
}

/*
* <span className={classes.paragraph}>or</span>
  <Button
    fullWidth
    variant="contained"
    className={classes.submit}
  >
    <img className={classes.socialIcon} height={24} width={24} src="./google.svg" alt="google" />
    {' '}
    Continue with Google
  </Button>
  <Button
    fullWidth
    variant="contained"
    className={classes.submit}
  >
    <img className={classes.socialIcon} height={24} width={24} src="./facebook.svg" alt="google" />
    {' '}
    Continue with Facebook
  </Button> */
