import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers';

let middleware = applyMiddleware(promise, thunk);

if (process.env.NODE_ENV === 'development') {
  middleware = composeWithDevTools(applyMiddleware(promise, thunk, logger));
}

export default createStore(reducer, middleware);
