import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect,
  NavLink,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider, makeStyles, lighten } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme, TextField } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/CheckCircle';
import FilterListIcon from '@material-ui/icons/FilterList';
import Main from './Main';
import Login from './Login';
import AdminRoute from './AdminRoute';
import PrivateRoute from './PrivateRoute';
import Register from './Register';
import { addCommand, fetchCommands, deleteCommands } from '../actions/productActions';
import { fetchUser, updateLocalUser } from '../actions/userActions';
import ImageLeguman from '../assets/images/index_logo_tmp.png';
import SuccessLogo from '../assets/images/success.gif';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#263431',
    },
    secondary: {
      main: '#0B7753',
    },
  },
});

function Home() {
  const useStyles = makeStyles((homeTheme) => ({
    root: {
      flexGrow: 1,
      padding: '1em',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: homeTheme.spacing(2),
    },
    imageHome: {
      position: 'relative',
      maxWidth: '100%',
      borderRadius: '1em',
    },
    innerText: {
      textAlign: 'center',
      '& p': {
        fontSize: '1.2em',
      },
    },
    fixLink: {
      textDecoration: 'none',
    },
  }));
  const classes = useStyles();
  return (
    <Grid container className={classes.root} justify="center" spacing={2}>
      <Grid container justify="center" item xs={12}>
        <Grid item xs={12} md={6}>
          <img className={classes.imageHome} src={ImageLeguman} alt="home left media" />
        </Grid>
      </Grid>
      <Grid container justify="center" item xs={12}>
        <Grid className={classes.innerText} item xs={12} md={6}>
          <p>
            Les jardins de Leo vous confectionne des produits dérivés de l&apos;agriculture soupes,
            gratins, confitures, etc...
          </p>
          <p>
            Profitant des cycles de rotation des marchés et grands magasins nous avons mis en
            place des dons associatifs et les paniers
            {' '}
            <strong>antigaspi</strong>
            {' '}
            de fruits et légumes en vrac 3-4kg 20CHF via son service de
            livraison &quot;leguman&quot;
          </p>
          <p>
            Paiement cash sur place ou via TWINT
          </p>
          <NavLink className={classes.fixLink} to="/products">
            <Button variant="contained" color="primary">Commander maintenant</Button>
          </NavLink>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Success() {
  const useStyles = makeStyles((successTheme) => ({
    root: {
      flexGrow: 1,
      padding: '1em',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: successTheme.spacing(2),
    },
    imageHome: {
      position: 'relative',
      maxWidth: '100%',
      borderRadius: '1em',
    },
    innerText: {
      textAlign: 'center',
      '& p': {
        fontSize: '1.2em',
      },
    },
    fixLink: {
      textDecoration: 'none',
    },
    primary: {
      color: '#0B7753',
    },
  }));
  const classes = useStyles();
  const lastCommand = useSelector((state) => state.product.user_command);
  const currentUser = useSelector((state) => state.user.user);

  return (
    <Grid container className={classes.root} justify="center" spacing={2}>
      <Grid container justify="center" item xs={12}>
        <Grid item xs={12} md={6}>
          <img className={classes.imageHome} src={SuccessLogo} alt="success media" />
        </Grid>
      </Grid>
      <Grid container justify="center" item xs={12}>
        <Grid className={classes.innerText} item xs={12} md={6}>
          <h2>Merci pour votre commande!</h2>
          <p>Votre commande a bien été enregistrée et est en cours de traitement par nos équipes</p>
          <p>
            Nous vous contacterons sur numéro de téléphone
            {' '}
            <strong style={{ color: '#0B7753' }}>{currentUser && currentUser.phoneNumber}</strong>
            {' '}
            dans les plus brefs délais pour convenir d&apos;un rendez-vous
          </p>
          <em>
            Numéro de commande :
            {' '}
            <strong className={classes.primary}>{lastCommand && lastCommand.id ? lastCommand.id : ''}</strong>
          </em>
          <p>
            <NavLink className={classes.fixLink} to="/">
              <Button variant="contained" color="primary">Page d&apos;acceuil</Button>
            </NavLink>
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}

function About() {
  const useStyles = makeStyles((homeTheme) => ({
    root: {
      flexGrow: 1,
      padding: '1em',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: homeTheme.spacing(2),
    },
    imageHome: {
      position: 'relative',
      maxWidth: '100%',
      borderRadius: '1em',
    },
    innerText: {
      textAlign: 'center',
      '& p': {
        fontSize: '1.2em',
      },
    },
    fixLink: {
      textDecoration: 'none',
    },
  }));
  const classes = useStyles();
  return (
    <Grid container justify="center" item xs={12}>
      <Grid className={classes.innerText} item xs={12}><h2>A propos</h2></Grid>
      <Grid className={classes.innerText} item xs={12}>
        <p>
          Les jardins de Leo vous confectionne des produits dérivés de l&apos;agriculture soupes,
          gratins, confitures, etc...
        </p>
        <p>
          Profitant des cycles de rotation des marchés et grands magasins nous avons mis en
          place des dons associatifs et les paniers
          {' '}
          <strong>antigaspi</strong>
          {' '}
          de fruits et légumes en vrac 3-4kg 20CHF via son service de
          livraison &quot;leguman&quot;
        </p>
        <p>
          Paiement cash sur place ou via TWINT
        </p>
      </Grid>
    </Grid>
  );
}

function Products() {
  const useStyles = makeStyles((themeApp) => ({
    root: {
      flexGrow: 1,
      padding: '1em',
    },
    cardRoot: {
      maxWidth: 345,
    },
    media: {
      height: 340,
      width: '100%',
      maxWidth: '100%',
      [themeApp.breakpoints.down('sm')]: {
        height: 200,
        width: '100%',
      },
    },
    wrapper: {
      padding: '1em 2em',
      [themeApp.breakpoints.down('sm')]: {
        padding: '0 0.5em',
      },
    },
    fixButton: {
      width: '100%',
      textDecoration: 'none',
      '& button': {
        width: '100%',
      },
    },
    highLightPrice: {
      color: '#0B7753',
      fontWeight: 'bold',
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <div className={classes.wrapper}>
        <Grid container className={classes.root} justify="center" spacing={2}>
          <Grid container justify="center" item xs={12} md={4}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/product_1.jpg"
                  title="Panier 2kg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    <span>Panier de 3Kg </span>
                    {' '}
                    <span className={classes.highLightPrice}>15CHF</span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    * Livraison incluse
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <NavLink className={classes.fixButton} to="/products/checkout/1">
                  <Button size="small" variant="contained" color="primary">
                    commander
                  </Button>
                </NavLink>
              </CardActions>
            </Card>
          </Grid>
          <Grid container justify="center" item xs={12} md={4}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image="/product_2.jpg"
                  title="Panier 3kg"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    <span>Panier de 4Kg </span>
                    {' '}
                    <span className={classes.highLightPrice}>20CHF</span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    * Livraison incluse
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <NavLink className={classes.fixButton} to="/products/checkout/2">
                  <Button size="small" variant="contained" color="primary">
                    commander
                  </Button>
                </NavLink>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function Checkout() {
  const useStyles = makeStyles((themeCheckout) => ({
    root: {
      flexGrow: 1,
      padding: '1em',
    },
    cardRoot: {
      maxWidth: 345,
    },
    media: {
      height: 340,
      width: '100%',
      maxWidth: '100%',
      [themeCheckout.breakpoints.down('sm')]: {
        height: 200,
        width: '100%',
      },
    },
    wrapper: {
      padding: '1em 2em',
      [themeCheckout.breakpoints.down('sm')]: {
        padding: '0 0.5em',
      },
    },
    fixButton: {
      width: '100%',
      textDecoration: 'none',
      '& button': {
        width: '100%',
      },
    },
    highLightPrice: {
      color: '#0B7753',
      fontWeight: 'bold',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    fixPadding: {
      paddingLeft: '0.5em',
    },
  }));
  const classes = useStyles();
  const location = useLocation();
  const currentUser = useSelector((state) => state.user.user);
  const productNumber = location.pathname.split('/')[3];
  const [quantity, setQuantity] = useState(1);
  const handleChange = (event) => {
    setQuantity(event.target.value);
  };
  const productPrice = productNumber === '1' ? 15 : 20;
  const dispatch = useDispatch();
  const commandPrice = parseInt(`${quantity}`, 10) * productPrice;

  const handleSubmit = () => {
    dispatch(addCommand({
      price: `${commandPrice}CHF`,
      quantity,
    }, currentUser));
  };

  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.root} justify="center" spacing={2}>
        <Grid container justify="center" item xs={12} md={4} lg={6}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.media}
              image={`/product_${productNumber}.jpg`}
              title="Panier 2kg"
            />
            <CardContent>
              <Typography className={classes.fixPadding} gutterBottom variant="h5" component="h2">
                Vérifiez votre commande
              </Typography>
              <h3 className={classes.fixPadding}>
                <span>
                  <span className={classes.highLightPrice}>
                    {quantity}
                  </span>
                  {' '}
                  Panier
                  {quantity > 1 ? 's' : ''}
                  {' '}
                  de
                  {' '}
                  {productNumber === '1' ? '3' : '4'}
                  Kg
                </span>
                {' '}
                <span className={classes.highLightPrice}>
                  {commandPrice}
                  CHF
                </span>
              </h3>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label">Quantité :</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={quantity}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  id="user-read-only-input"
                  label="Livrée chez :"
                  defaultValue={
                      currentUser
                      && currentUser.firstName
                      && currentUser.lastName
                        ? `${currentUser.firstName} ${currentUser.lastName}`
                        : ''
                    }
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  id="address-read-only-input"
                  label="Livrée à l'adresse :"
                  defaultValue={currentUser && currentUser.address ? currentUser.address : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  id="tel-read-only-input"
                  label="Téléphone :"
                  defaultValue={currentUser && currentUser.phoneNumber ? currentUser.phoneNumber : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </FormControl>
            </CardContent>
            <CardActions>
              <NavLink onClick={handleSubmit} className={classes.fixButton} to="/success">
                <Button size="small" variant="contained" color="primary">
                  valider
                </Button>
              </NavLink>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

function Page404() {
  return <h2 style={{ padding: '1em 2em' }}>Page 404</h2>;
}

function Page303() {
  return <h2 style={{ padding: '1em 2em' }}>Page 303</h2>;
}

const supportsHistory = 'pushState' in window.history;

function createData(id, name, address, price, quantity, tel, email, doorCode, floor, action) {
  return {
    id, name, address, price, quantity, tel, email, doorCode, floor, action,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// name, address, price, quantity, tel,email,status

const headCells = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Nom',
  },
  {
    id: 'address', numeric: false, disablePadding: true, label: 'Adresse',
  },
  {
    id: 'price', numeric: false, disablePadding: true, label: 'Prix',
  },
  {
    id: 'quantity', numeric: false, disablePadding: true, label: 'Quantité',
  },
  {
    id: 'tel', numeric: false, disablePadding: true, label: 'tel',
  },
  {
    id: 'email', numeric: false, disablePadding: true, label: 'email',
  },
  {
    id: 'doorCode', numeric: false, disablePadding: true, label: 'code',
  },
  {
    id: 'floor', numeric: false, disablePadding: true, label: 'Etage',
  },
  {
    id: 'action', numeric: false, disablePadding: true, label: 'action',
  },
];

function EnhancedTableHead(props) {
  const {
    classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((themeToolbar) => ({
  root: {
    paddingLeft: themeToolbar.spacing(2),
    paddingRight: themeToolbar.spacing(1),
  },
  highlight:
      themeToolbar.palette.type === 'light'
        ? {
          color: themeToolbar.palette.secondary.main,
          backgroundColor: lighten(themeToolbar.palette.secondary.light, 0.85),
        }
        : {
          color: themeToolbar.palette.text.primary,
          backgroundColor: themeToolbar.palette.secondary.dark,
        },
  title: {
    flex: '1 1 80%',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5em',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,0.05)',
    '& svg': {
      marginRight: '0.25em',
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, handleDelete } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected}
          {' '}
          selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Commandes
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Marquer comme Livré">
          <Button onClick={handleDelete} className={classes.buttonWrapper}>
            <DeleteIcon />
            {' '}
            Marqué comme livré
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((themeTable) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: themeTable.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const CommandList = () => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { commands } = useSelector((state) => state.product, shallowEqual);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    /* rows.map((n: any) => n.id) */
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const dispatch = useDispatch();
  const location = useLocation();
  const callback = () => {
    dispatch(fetchCommands());
    setSelected([]);
  };

  const handleDelete = () => {
    dispatch(deleteCommands([...selected], callback));
  };
  const handleDeleteItem = (event, itemId) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(deleteCommands([itemId], callback));
  };
  useEffect(() => {
    dispatch(fetchCommands());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    setRows([...new Set(commands.map((command) => createData(
      command.id,
      command.customer,
      command.address,
      command.price,
      command.quantity,
      command.tel,
      command.user.email,
      command.doorCode,
      command.floor,
      <Button onClick={(event) => handleDeleteItem(event, command.id)} variant="contained" color="primary">Livré</Button>,
    )))]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commands]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar handleDelete={handleDelete} numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell padding="none" align="left">{row.address}</TableCell>
                      <TableCell padding="none" align="left">{row.price}</TableCell>
                      <TableCell padding="none" align="left">{row.quantity}</TableCell>
                      <TableCell padding="none" align="left">{row.tel}</TableCell>
                      <TableCell padding="none" align="left">{row.email}</TableCell>
                      <TableCell padding="none" align="left">{row.doorCode ? row.doorCode : 'n/a'}</TableCell>
                      <TableCell padding="none" align="left">{row.floor ? row.floor : 'n/a'}</TableCell>
                      <TableCell padding="none" align="left">{row.action}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

function App() {
  /**
   * @desc Is user already logged
   * @constant
   * @type {(boolean|firebase.User)|boolean}
   */
  const auth = firebase.auth();
  const currentUser = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [, setAccess] = useState(false);

  /**
   * @desc Sign out callback
   * @returns {undefined}
   * @function
   */
  const handleSignOut = (history) => {
    firebase.auth().signOut().then(() => {
      dispatch(updateLocalUser(null));
      history.push('/home');
    }).catch(() => {
      // console.log(error);
    });
  };

  useEffect(() => {
    auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        dispatch(fetchUser(firebaseUser));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (currentUser) {
      setAccess(true);
    }
  }, [currentUser]);

  return (
    <ThemeProvider theme={theme}>
      <Router forceRefresh={!supportsHistory}>
        <div style={{ backgroundColor: '#FFEA99', minHeight: '100vh' }}>
          <Switch>
            <Route strict exact path="/about">
              <Main handleSignOut={handleSignOut}><About /></Main>
            </Route>
            <Route strict exact path="/products">
              <Main handleSignOut={handleSignOut}><Products /></Main>
            </Route>
            <PrivateRoute
              strict
              exact
              path="/products/checkout/:productID"
              component={Checkout}
              user={currentUser}
              handleSignOut={handleSignOut}
            />
            <PrivateRoute
              strict
              exact
              path="/success"
              component={Success}
              user={currentUser}
              handleSignOut={handleSignOut}
            />
            <Route strict exact path="/home">
              <Main handleSignOut={handleSignOut}><Home /></Main>
            </Route>
            <Route strict exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route
              strict
              exact
              path="/login"
              render={() => (
                !currentUser
                  ? <Login />
                  : <Redirect to="/products/checkout/1" />
              )}
            />
            <Route strict exact path="/register">
              <Register />
            </Route>
            <Route strict exact path="/303">
              <Page303 />
            </Route>
            <AdminRoute
              strict
              exact
              path="/admin"
              component={CommandList}
              user={currentUser}
              handleSignOut={handleSignOut}
            />
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
