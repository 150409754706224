// USER TYPES
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_GEOLOCATION = 'UPDATE_USER_GEOLOCATION';
export const NEW_USER = 'NEW_USER';
export const ERROR_NEW_USER = 'ERROR_NEW_USER';
export const ERROR_UPDATE_USER = 'ERROR_UPDATE_USER';
export const ERROR_FETCH_USER = 'ERROR_FETCH_USER';
export const GET_USER_LOCATION = 'GET_USER_LOCATION';

// PRODUCT TYPES
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_COMMANDS = 'FETCH_COMMANDS';
export const ERROR_FETCH_COMMANDS = 'ERROR_FETCH_COMMANDS';
export const NEW_PRODUCT = 'NEW_PRODUCT';
export const CHOOSE_PRODUCT = 'CHOOSE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ERROR_NEW_PRODUCT = 'ERROR_NEW_PRODUCT';
export const ERROR_UPDATE_PRODUCT = 'ERROR_UPDATE_PRODUCT';
export const ADD_COMMAND = 'ADD_COMMAND';
export const ERROR_ADD_COMMAND = 'ERROR_ADD_COMMAND';
