import firebase from 'firebase/app';
import {
  FETCH_USER,
  NEW_USER,
  ERROR_NEW_USER,
  GET_USER_LOCATION,
  ERROR_FETCH_USER,
  UPDATE_USER,
  UPDATE_USER_GEOLOCATION,
  ERROR_UPDATE_USER,
} from './types';
import 'firebase/firestore';

export const fetchUser = (firebaseUser) => {
  const db = firebase.firestore();

  return (dispatch) => {
    const userUid = firebaseUser.uid;
    const userRef = db.collection('users').doc(userUid);

    userRef.get().then((user) => {
      if (user && user.exists) {
        dispatch({
          type: FETCH_USER,
          payload: {
            user: user.data(),
          },
        });
      } else {
        dispatch({
          type: ERROR_FETCH_USER,
          payload: { error: 'no user found' },
        });
      }
    })
      .catch((error) => {
        // console.log('Error getting document:', error);
        dispatch({
          type: ERROR_FETCH_USER,
          payload: { error },
        });
      });
  };
};

export const createUser = (firebaseUser) => {
  const db = firebase.firestore();
  /* if (window.location.hostname === 'localhost') {
    try {
      db.settings({
        host: 'localhost:6001',
        ssl: false,
      });
    } catch (error) {
      // console.error(error);
    }
  } */

  return (dispatch) => {
    const newUser = {
      firstName: (firebaseUser.firstName) ? firebaseUser.firstName : 'User',
      lastName: (firebaseUser.lastName) ? firebaseUser.lastName : 'Default',
      address: (firebaseUser.address) ? firebaseUser.address : 'n/a',
      birth: '',
      displayName: (firebaseUser.displayName) ? firebaseUser.displayName : 'User Default',
      email: (firebaseUser.email) ? firebaseUser.email : '',
      emailVerified: (firebaseUser.emailVerified) ? firebaseUser.emailVerified : false,
      location: null,
      phoneNumber: (firebaseUser.phoneNumber) ? firebaseUser.phoneNumber : '',
      phoneVerified: false,
      photoUrl: (firebaseUser.photoURL) ? firebaseUser.photoURL : '/img/default_user_pic.png',
      uid: firebaseUser.uid,
      doorCode: (firebaseUser.doorCode) ? firebaseUser.doorCode : '',
      floor: (firebaseUser.floor) ? firebaseUser.floor : '',
    };

    db.collection('users').doc(firebaseUser.uid)
      .set(newUser)
      .then(() => {
        dispatch({
          type: NEW_USER,
          payload: {
            user: newUser,
          },
        });
      })
      .catch((error) => {
        // console.log('Error fetching user: ', error);
        dispatch({
          type: ERROR_NEW_USER,
          payload: { error },
        });
      });
  };
};

export const updateUser = (user, payload, callback) => {
  const db = firebase.firestore();
  /* if (window.location.hostname === 'localhost') {
    try {
      db.settings({
        host: 'localhost:6001',
        ssl: false,
      });
    } catch (error) {
      // console.error(error);
    }
  } */
  const userUid = user.uid;
  return (dispatch) => {
    const userRef = db.collection('users').doc(userUid);

    userRef.update(payload)
      .then(() => {
        if (callback) { callback(); }
        dispatch({
          type: UPDATE_USER,
          payload: { user },
        });
      })
      .catch((error) => {
        // console.log('Error updating User: ', error);
        dispatch({
          type: ERROR_UPDATE_USER,
          payload: { error },
        });
      });
  };
};

export const updateLocalUser = (user) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: { user },
  });
};

export const getUserLocation = () => (dispatch) => {
  fetch('https://ipinfo.io/json')
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (contentType && (contentType.indexOf('application/json') !== -1)) {
        return response.json().then((location) => {
          dispatch({
            type: GET_USER_LOCATION,
            payload: { location },
          });
        });
      }
      // console.log('Error parsing JSON!', '');
      return response.text().then(() => {
        dispatch({
          type: ERROR_FETCH_USER,
          payload: { error: true },
        });
      });
    })
    .catch(() => {
      // console.log('Error user location: ', error);
    });
};

export const updateUserGeolocation = (mapGeolocation) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_GEOLOCATION,
    payload: { mapGeolocation },
  });
};
