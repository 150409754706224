import { Redirect, Route } from 'react-router-dom';
import Main from './Main';

const AdminRoute = ({
  component: Component, handleSignOut, user, ...rest
}) => (
  <Route
    strict
    exact
    {...rest}
    render={(props) => (
      user && user.isAdmin
        ? <Main handleSignOut={handleSignOut}><Component {...props} /></Main>
        : <Redirect to="/303" />
    )}
  />
);

export default AdminRoute;
