import { Redirect, Route } from 'react-router-dom';
import Main from './Main';

const PrivateRoute = ({
  component: Component, handleSignOut, user, ...rest
}) => (
  <Route
    strict
    exact
    {...rest}
    render={(props) => (
      user
        ? <Main handleSignOut={handleSignOut}><Component {...props} /></Main>
        : <Redirect to="/login" />
    )}
  />
);

export default PrivateRoute;
