import { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { Container } from '@material-ui/core';
// import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
/* import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail'; */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../assets/images/logo_leguman.svg';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  toolbarButton: {
    color: '#000000',
    position: 'relative',
    right: '1em',
  },
  appBar: {
    boxShadow: 'none',
    border: 'none',
  },
  media: {
    height: '80px',
    width: '80px',
  },
  title: {
    display: 'inline-flex',
    position: 'relative',
    left: '-23px',
    top: '7px',
    fontWeight: 'bold',
  },
  spacer: {
    display: 'flex',
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    padding: '0 1em',
  },
  drawerNavigationContainer: {
    display: 'flex',
    padding: '1em',
    flexDirection: 'column',
    minHeight: '100vh',
    '& > *': {
      display: 'flex',
    },
    '& a': {
      paddingLeft: '1.2em',
      fontSize: '1.1em',
    },
  },
  navLink: {
    display: 'inline-flex',
    color: '#000000',
    marginRight: '1em',
    textDecoration: 'none',
    '&.selected': {
      color: '#0B7753',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '50vw',
      margin: '0.25em 0',
      fontSize: '1.4em',
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  fixIcon: {
    marginRight: '1em',
  },
  bottomMenu: {
    display: 'flex',
    alignSelf: 'flex-end',
    width: '100%',
    padding: 0,
  },
  logoutLabel: {
    display: 'flex',
    flex: 1,
  },
  logoLink: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
}));

const Main = ({ handleSignOut, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useSelector((state) => state.user.user);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };
  const closeDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(false);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSignOut = (event) => {
    closeDrawer(event);
    handleClose();
    handleSignOut(history);
  };

  return (
    <>
      <CssBaseline />
      <AppBar className={classes.appBar} position="relative" color="transparent">
        <Container maxWidth="lg">
          <Toolbar>
            <NavLink className={`${classes.navLink} ${classes.logoLink}`} to="/home">
              <img height={30} src={Logo} alt="leguman" />
            </NavLink>
            <span className={classes.spacer} />
            {
              matches
                ? (
                  <>
                    <div className={classes.navigationContainer}>
                      <NavLink className={classes.navLink} activeClassName="selected" to="/home">Accueil</NavLink>
                      <NavLink className={classes.navLink} activeClassName="selected" to="/products">Produits</NavLink>
                      {
                        currentUser && currentUser.isAdmin
                          ? <NavLink className={classes.navLink} activeClassName="selected" to="/admin">Commandes</NavLink>
                          : null
                      }
                    </div>
                    {
                      currentUser
                        ? (
                          <div>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                              <MenuIcon />
                            </Button>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={handleMenuSignOut}>
                                <PowerSettingsNewIcon className={classes.fixIcon} fontSize="small" />
                                {' '}
                                Se déconnecter
                              </MenuItem>
                            </Menu>
                          </div>
                        )
                        : null
                    }
                  </>
                )
                : <Button onClick={toggleDrawer}><MenuIcon /></Button>
            }
          </Toolbar>
        </Container>
      </AppBar>
      <main>
        <Container maxWidth="lg">
          {children}
          <>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
              <div className={classes.drawerNavigationContainer}>
                <MenuItem onClick={toggleDrawer}>
                  <NavLink className={classes.navLink} activeClassName="selected" to="/home">Accueil</NavLink>
                </MenuItem>
                <MenuItem onClick={toggleDrawer}>
                  <NavLink className={classes.navLink} activeClassName="selected" to="/products">Produits</NavLink>
                </MenuItem>
                <MenuItem onClick={toggleDrawer}>
                  <NavLink className={classes.navLink} activeClassName="selected" to="/about">A propos</NavLink>
                </MenuItem>
                {
                  currentUser && currentUser.isAdmin
                    ? (
                      <MenuItem onClick={toggleDrawer}>
                        <NavLink className={classes.navLink} activeClassName="selected" to="/admin">Commandes</NavLink>
                      </MenuItem>
                    )
                    : null
                }
                {
                  currentUser
                    ? (
                      <MenuItem className={classes.bottomMenu} onClick={handleMenuSignOut}>
                        <PowerSettingsNewIcon className={classes.fixIcon} fontSize="small" />
                        <div className={classes.logoutLabel}>Se déconnecter</div>
                      </MenuItem>
                    )
                    : null
                }
              </div>
            </Drawer>
          </>
        </Container>
      </main>
    </>
  );
};

export default Main;
