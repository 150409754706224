import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import validator from 'validator';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { NavLink, useHistory } from 'react-router-dom';
import { FormHelperText } from '@material-ui/core';
import firebase from 'firebase/app';
import { createUser } from '../actions/userActions';
import Logo from '../assets/images/logo_leguman.svg';

import 'firebase/auth';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paragraph: {
    width: '100%',
    textAlign: 'center',
    display: 'block',
  },
  formLabel: {
    '& span': {
      fontSize: '1em',
    },
  },
  socialIcon: {
    marginRight: '0.5em',
  },
  link: {
    color: '#263431',
  },
  globalError: {
    color: 'red',
  },
  media: {
    height: '200px',
    width: '100%',
    paddingTop: '56.25%', // 16:9
  },
  logo: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '1em',
  },
  textField: {
    '& > div': {
      backgroundColor: '#FFFFFF !important',
    },
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [doorCode, setDoorCode] = useState('');
  const [floor, setFloor] = useState('');
  const [checkbox, setCheckbox] = useState(true);
  const [globalError, setGlobalError] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    address: false,
    phone: false,
    password: false,
    checkbox: false,
    zipcode: false,
    city: false,
    country: false,
  });
  const isPhoneNumber = (phoneNumber) => /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/.test(phoneNumber);
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'firstName':
        setFirstName(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(event.target.value.trim()) ? false : { message: 'Ce champs est requis' },
        });
        break;
      case 'lastName':
        setLastName(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(event.target.value.trim()) ? false : { message: 'Ce champs est requis' },
        });
        break;
      case 'email':
        setEmail(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: validator.isEmail(event.target.value) ? false : { message: 'Veuillez fournir une adresse mail sous la forme mail@exemple.com' },
        });
        break;
      case 'address':
        setAddress(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(event.target.value.trim()) ? false : { message: 'Ce champs est requis' },
        });
        break;
      case 'address2':
        setAddress2(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(event.target.value.trim()) ? false : { message: 'Ce champs est requis' },
        });
        break;
      case 'zipcode':
        setZipcode(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(event.target.value.trim()) ? false : { message: 'Ce champs est requis' },
        });
        break;
      case 'city':
        setCity(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(event.target.value.trim()) ? false : { message: 'Ce champs est requis' },
        });
        break;
      case 'country':
        setCountry(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(event.target.value.trim()) ? false : { message: 'Ce champs est requis' },
        });
        break;
      case 'phone':
        setPhone(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: isPhoneNumber(event.target.value.trim()) ? false : { message: 'Ce champs est requis' },
        });
        break;
      case 'password':
        setPassword(event.target.value);
        setErrors({
          ...errors,
          [event.target.name]: !validator.isEmpty(password.trim()) && validator.isLength(event.target.value, { min: 8, max: undefined }) ? false : { message: 'Your password must be at least 8 characters' },
        });
        break;
      case 'doorCode':
        setDoorCode(event.target.value);
        break;
      case 'floor':
        setFloor(event.target.value);
        break;
      default:
        setCheckbox(event.target.checked);
    }
  };

  const handleValidate = () => {
    setErrors({
      firstName: !validator.isEmpty(firstName.trim()) ? false : { message: 'Ce champs est requis' },
      lastName: !validator.isEmpty(lastName.trim()) ? false : { message: 'Ce champs est requis' },
      email: validator.isEmail(email) ? false : { message: 'Veuillez fournir une adresse mail sous la forme mail@exemple.com' },
      address: !validator.isEmpty(address.trim()) ? false : { message: 'Ce champs est requis' },
      zipcode: !validator.isEmpty(zipcode.trim()) ? false : { message: 'Ce champs est requis' },
      city: !validator.isEmpty(city.trim()) ? false : { message: 'Ce champs est requis' },
      country: !validator.isEmpty(country.trim()) ? false : { message: 'Ce champs est requis' },
      phone: isPhoneNumber(phone.trim()) ? false : { message: 'Ce champs est requis' },
      password: !validator.isEmpty(password.trim()) && validator.isLength(password, { min: 8, max: undefined }) ? false : { message: 'Your password must be at least 8 characters' },
      checkbox: false,
    });

    return (
      !validator.isEmpty(firstName.trim())
      && !validator.isEmpty(lastName.trim())
      && validator.isEmail(email)
      && !validator.isEmpty(address.trim())
      && !validator.isEmpty(zipcode.trim())
      && !validator.isEmpty(city.trim())
      && !validator.isEmpty(country.trim())
      && isPhoneNumber(phone.trim())
      && !validator.isEmpty(password.trim())
      && validator.isLength(password, { min: 8, max: undefined })
    );
  };

  const handleSignUp = (payload) => {
    firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password).then(() => {
      firebase.auth().currentUser
        .sendEmailVerification()
        .then(() => {
          const firebaseUser = firebase.auth().currentUser;
          // Email sent.
          dispatch(createUser({
            uid: firebaseUser.uid,
            birth: '',
            displayName: (firebaseUser.displayName) ? firebaseUser.displayName : 'User Name',
            email,
            emailVerified: (firebaseUser.emailVerified) ? firebaseUser.emailVerified : false,
            location: null,
            phoneNumber: phone,
            phoneVerified: false,
            photoUrl: (firebaseUser.photoURL) ? firebaseUser.photoURL : '/img/default_user_pic.png',
            firstName,
            lastName,
            address: `${address} ${address2}, ${zipcode} ${city} ${country}`,
            doorCode,
            floor,
          }));
        }, () => {
          // An error happened.
          // console.log('Email Verification fail', error);
          setGlobalError(true);
        });
      history.push('/products');
    }).catch(() => {
      // console.log('Global error', error);
      setGlobalError(true);
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const validate = await handleValidate();

    if (validate) {
      handleSignUp({
        email, password,
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}><img height={30} src={Logo} alt="leguman" /></div>
        <Typography component="h1" variant="h5">
          S&apos;inscrire
        </Typography>
        <form onSubmit={onSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                value={firstName}
                onChange={handleChange}
                inputProps={{
                  name: 'firstName',
                }}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                id="firstName"
                label="Prénom"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName ? errors.firstName.message : ''}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textField}
                variant="filled"
                value={lastName}
                onChange={handleChange}
                required
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                inputProps={{
                  name: 'lastName',
                }}
                autoComplete="lname"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName ? errors.lastName.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                onChange={handleChange}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                inputProps={{
                  name: 'email',
                }}
                autoComplete="email"
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={address}
                onChange={handleChange}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                id="address"
                label="Rue et N°"
                name="address"
                inputProps={{
                  name: 'address',
                }}
                autoComplete="address"
                error={Boolean(errors.address)}
                helperText={errors.address ? errors.address.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={address2}
                onChange={handleChange}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                id="address2"
                label="Complément d'adresse"
                name="address2"
                inputProps={{
                  name: 'address2',
                }}
                autoComplete="address2"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={zipcode}
                onChange={handleChange}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                id="zipcode"
                label="Code postal"
                name="zipcode"
                inputProps={{
                  name: 'zipcode',
                }}
                autoComplete="zipcode"
                error={Boolean(errors.zipcode)}
                helperText={errors.zipcode ? errors.zipcode.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={city}
                onChange={handleChange}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                id="city"
                label="Ville"
                name="city"
                inputProps={{
                  name: 'city',
                }}
                autoComplete="city"
                error={Boolean(errors.city)}
                helperText={errors.city ? errors.city.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={country}
                onChange={handleChange}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                id="country"
                label="Pays"
                name="country"
                inputProps={{
                  name: 'country',
                }}
                autoComplete="country"
                error={Boolean(errors.country)}
                helperText={errors.country ? errors.country.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="doorCode"
                name="doorCode"
                value={doorCode}
                onChange={handleChange}
                inputProps={{
                  name: 'doorCode',
                }}
                className={classes.textField}
                variant="filled"
                fullWidth
                id="doorCode"
                label="Code entrée immeuble"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="floor"
                name="floor"
                value={floor}
                onChange={handleChange}
                inputProps={{
                  name: 'floor',
                }}
                className={classes.textField}
                variant="filled"
                fullWidth
                id="floor"
                label="Etage"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={phone}
                onChange={handleChange}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                id="phone"
                label="Téléphone"
                name="phone"
                inputProps={{
                  name: 'phone',
                }}
                autoComplete="phone"
                error={Boolean(errors.phone)}
                helperText={errors.phone ? errors.phone.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={password}
                onChange={handleChange}
                className={classes.textField}
                variant="filled"
                required
                fullWidth
                name="password"
                inputProps={{
                  name: 'password',
                }}
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className={classes.formLabel}
                control={(
                  <Checkbox
                    name="allowExtraEmails"
                    inputProps={{
                      name: 'allowExtraEmails',
                    }}
                    checked={checkbox}
                    value="allowExtraEmails"
                    color="primary"
                    onChange={handleChange}
                  />
)}
                label="Je souhaite recevoir des promotions et des mises à jour par courrier électronique."
              />
            </Grid>
          </Grid>
          {
            globalError
              ? (
                <FormHelperText className={classes.globalError}>
                  Une erreur s&apos;est produite en essayant de traiter votre demande.
                  Veuillez réessayer plus tard.
                </FormHelperText>
              )
              : null
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            S&apos;inscrire
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <NavLink to="/login">
                <span className={classes.link}>
                  Vous avez déjà un compte ? Connectez-vous
                </span>
              </NavLink>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

/*
<span className={classes.paragraph}>or</span>
<Button
  fullWidth
  variant="contained"
  className={classes.submit}
>
  <img className={classes.socialIcon} height={24} width={24} src="./google.svg" alt="google" />
  {' '}
  Continue with Google
</Button>
<Button
  fullWidth
  variant="contained"
  className={classes.submit}
>
  <img className={classes.socialIcon} height={24} width={24} src="./facebook.svg" alt="google" />
  {' '}
  Continue with Facebook
</Button> */
