import {
  FETCH_PRODUCT,
  NEW_PRODUCT,
  UPDATE_PRODUCT,
  ADD_COMMAND,
  ERROR_NEW_PRODUCT,
  ERROR_UPDATE_PRODUCT,
  ERROR_ADD_COMMAND,
  CHOOSE_PRODUCT,
  FETCH_COMMANDS,
  ERROR_FETCH_COMMANDS,
} from '../actions/types';

const userReducer = (state = {
  product: null,
  user_command: null,
  error: null,
  commands: [],
}, action) => {
  switch (action.type) {
    case FETCH_PRODUCT:
      return {
        ...state,
        product: action.payload.product,
      };
    case FETCH_COMMANDS:
      return {
        ...state,
        commands: action.payload.commands,
      };
    case ERROR_FETCH_COMMANDS:
      return {
        ...state,
        error: action.payload.error,
      };
    case CHOOSE_PRODUCT:
      return {
        ...state,
        product: action.payload.product,
      };
    case ADD_COMMAND:
      return {
        ...state,
        user_command: action.payload.new_command,
      };
    case NEW_PRODUCT:
      return {
        ...state,
        product: action.payload.product,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        product: action.payload.product,
      };
    case ERROR_NEW_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
      };
    case ERROR_UPDATE_PRODUCT:
      return {
        ...state,
        error: action.payload.error,
      };
    case ERROR_ADD_COMMAND:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default userReducer;
