import {
  FETCH_USER,
  NEW_USER,
  GET_USER_LOCATION,
  UPDATE_USER,
  UPDATE_USER_GEOLOCATION,
  ERROR_UPDATE_USER,
  ERROR_NEW_USER,
} from '../actions/types';

const userReducer = (state = {
  user: null,
  error: null,
  location: null,
  mapGeolocation: null,
}, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case NEW_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case UPDATE_USER_GEOLOCATION:
      return {
        ...state,
        mapGeolocation: action.payload.mapGeolocation,
      };
    case ERROR_NEW_USER:
      return {
        ...state,
        error: action.payload.error,
      };
    case ERROR_UPDATE_USER:
      return {
        ...state,
        error: action.payload.error,
      };
    case GET_USER_LOCATION:
      return {
        ...state,
        location: action.payload.location,
      };
    default:
      return state;
  }
};

export default userReducer;
